

































































































































































































































.operationControl {
  border-bottom: none;
  .searchBox {
    padding: 0 !important;
    .el-icon-arrow-down:before {
      content: '\e6df' !important;
    }
  }
  .searchboxItem {
    margin-right: 30px;
  }
}
.el-main {
  display: flex;
  flex-direction: initial;
  > .el-form {
    display: flex;
    flex-direction: initial;
    flex: 1;
    > .flex {
      width: 48%;
      h3 {
        margin-bottom: 15px;
      }
    }
    .el-divider--vertical {
      height: 100%;
      margin: 0 2%;
    }
  }
}
.el-footer {
  display: flex;
  align-items: center;
  justify-content: center;
}
.el-button {
  padding: 12px 40px;
}
.el-input-number--mini {
  width: 70px;
}
.el-switch:not(.no) {
  margin-right: 20px;
}
.el-select {
  /deep/ .el-input {
    width: 80px;
  }
}
