.operationControl {
  border-bottom: none;
}
.operationControl .searchBox {
  padding: 0 !important;
}
.operationControl .searchBox .el-icon-arrow-down:before {
  content: '\e6df' !important;
}
.operationControl .searchboxItem {
  margin-right: 30px;
}
.el-main {
  display: flex;
  flex-direction: initial;
}
.el-main > .el-form {
  display: flex;
  flex-direction: initial;
  flex: 1;
}
.el-main > .el-form > .flex {
  width: 48%;
}
.el-main > .el-form > .flex h3 {
  margin-bottom: 15px;
}
.el-main > .el-form .el-divider--vertical {
  height: 100%;
  margin: 0 2%;
}
.el-footer {
  display: flex;
  align-items: center;
  justify-content: center;
}
.el-button {
  padding: 12px 40px;
}
.el-input-number--mini {
  width: 70px;
}
.el-switch:not(.no) {
  margin-right: 20px;
}
.el-select /deep/ .el-input {
  width: 80px;
}
